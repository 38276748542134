export enum KlaviyoEventEnum {
  VIEWED_PRODUCT = 'Viewed Product',
  ADDED_TO_CART = 'Added to Cart'
}

export enum CurrencyTypeEnum {
  GBP = 'GBP'
}

export enum WebsiteContentsEnum {
  HOW_TO_PLAY = 'HOW_TO_PLAY',
  ABOUT_US = 'ABOUT_US',
  FAQ = 'FAQ',
  TNC = 'TNC',
  BLOGS = 'BLOGS',
  HOME_PAGE = 'HOME',
  ACTIVE_COMPETITIONS = 'ACTIVE_COMPETITIONS',
  LIVE_DRAWS = 'LIVE_DRAWS',
  WINNERS_PODIUM = 'WINNERS_PODIUM',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  HOW_IT_WORKS = 'HOW_IT_WORKS',
  PRIVACY_POLICY = 'PRIVACY_POLICY',
  BANNER_CONTENT = 'BANNER_TEXT',
  PAST_COMPETITIONS = 'PAST_COMPETITIONS',
  COMPETITION_CATEGORIES = 'COMPETITION_CATEGORIES',
  DOWNLOAD_APP = 'DOWNLOAD_APP',
  SOCIAL_STATS = 'SOCIAL_STATS',
  INSTANT_WINNERS = 'INSTANT_WINNERS'
}

export enum IntentEnum {
  RECHECK = 'recheck'
}

export enum CompetitionCategorySlugEnum {
  CAR_COMPETITIONS = 'car-competitions',
  CASH_COMPETITIONS = 'cash-competitions',
  WATCH_COMPETITIONS = 'watch-competitions'
}

export enum QueryClientKeysEnum {
  // region Influencer

  GET_INFLUENCER_REQUEST_STATUS = 'GET_INFLUENCER_REQUEST_STATUS',
  GET_LEADER_BOARD = 'GET_LEADER_BOARD',
  GET_INFLUENCER_PROFILE = 'GET_INFLUENCER_PROFILE',
  GET_EARNINGS = 'GET_EARNINGS',
  GET_COUPON_USAGE = 'GET_COUPON_USAGE',

  // endregion

  // region Competitions

  GET_COMPETITION_JACKPOT = 'GET_COMPETITION_JACKPOT',
  GET_COMPETITION_DETAILS = 'GET_COMPETITION_DETAILS',
  GET_COMPETITIONS = 'GET_COMPETITIONS',

  // endregion

  // region Profile

  GET_PROFILE = 'GET_PROFILE',
  GET_KLAVIYO_SUBSCRIPTION_STATUS = 'GET_KLAVIYO_SUBSCRIPTION_STATUS',

  // endregion

  // Post Checkout

  GET_TRANSACTION_OVERVIEW = 'GET_TRANSACTION_OVERVIEW',

  // endregion

  GET_WALLET_TRANSACTIONS = 'GET_WALLET_TRANSACTIONS',
  GET_APPLICATION_CONFIG = 'GET_APPLICATION_CONFIG',
  GET_COMPETITION_WINNER = 'GET_COMPETITION_WINNER',
  GET_LIVE_DRAWS = 'GET_LIVE_DRAWS',
  GET_INSTANT_PRIZES = 'GET_INSTANT_PRIZES',
  CALCULATE_ALTERNATIVE_CREDIT = 'CALCULATE_ALTERNATIVE_CREDIT',
  GET_CATEGORIES = 'GET_CATEGORIES',
  GET_PROMOTION_BANNER_TEXT = 'GET_PROMOTION_BANNER_TEXT',
  GET_WINNERS = 'GET_WINNERS',
  GET_TICKETS = 'GET_TICKETS',
  GET_USER_TRANSACTIONS = 'GET_USER_TRANSACTIONS',
  GET_PAST_COMPETITIONS = 'GET_PAST_COMPETITIONS',
  GET_COMPETITION_INSTANT_PRIZES_GROUPS = 'GET_COMPETITION_INSTANT_PRIZES_GROUPS',
  GET_INSTANT_PRIZE_GROUP_WINNERS = 'GET_INSTANT_PRIZE_GROUP_WINNERS',
  GET_TESTIMONIALS = 'GET_TESTIMONIALS',
  GET_USER_LAST_TICKET = 'GET_USER_LAST_TICKET',
  GET_HOMEPAGE_CONTENT = 'GET_HOMEPAGE_CONTENT',
  GET_SOCIAL_STATS_CONTENT = 'GET_SOCIAL_STATS_CONTENT'
}

export enum TicketAvailabilityStatusEnum {
  AVAILABLE = 0,
  NOT_AVAILABLE = 1,
  ERROR = 2,
  UNKNOWN = 3
}

export enum PerPageItemsEnum {
  VALUE_6 = 6,
  VALUE_9 = 9,
  VALUE_10 = 10,
  VALUE_15 = 15,
  VALUE_20 = 20,
  VALUE_25 = 25,
  VALUE_50 = 50,
  VALUE_100 = 100
}
