import { type ErrorType } from '@elitecompetitions/client-api'

export type ServerError = {
  statusCode: number
  message: string
  timestamp: string
  errors: ErrorType[]
}

export const isServerError = (error: any): error is ServerError => {
  return error?.statusCode && error?.message && Array.isArray(error?.errors)
}

export const getServerError = (error: any): ServerError | null => {
  if (error && error.body) {
    const e = error.body

    if (isServerError(e)) {
      return e
    }
  }

  return null
}
