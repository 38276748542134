import * as Sentry from '@sentry/nextjs'

import {
  type CompetitionBaseDto,
  type CompetitionDetailsDTO,
  type ReservedTicketDTO,
  type UserTicketDTO
} from '@elitecompetitions/client-api'

import { matomo } from './matomo'
import { adalyser } from './adalyser'

const callTrackers = (eventName, ...args) => {
  try {
    matomo[eventName]?.(...args)
    adalyser[eventName]?.(...args)
  } catch (error) {
    console.error(`Error in tracking event: ${eventName}`, error)
    Sentry.captureException(error)
  }
}

const tracking = {
  customEvent: (eventName, payload) =>
    callTrackers('customEvent', eventName, payload),

  begin_checkout: ({
    uniqueCartTickets,
    couponCode,
    value
  }: {
    uniqueCartTickets: Array<{
      competition: CompetitionBaseDto | CompetitionDetailsDTO
      tickets: ReservedTicketDTO[]
    }>
    couponCode?: string
    value: number
  }) =>
    callTrackers('begin_checkout', {
      items: uniqueCartTickets,
      couponCode,
      value
    }),

  view_product: (item: CompetitionBaseDto | CompetitionDetailsDTO) =>
    callTrackers('view_product', item),

  add_to_cart: (
    item: CompetitionBaseDto | CompetitionDetailsDTO,
    options: Partial<{
      suffix: string
      overRideValue: boolean
      val: number
      quantity: number
    }>
  ) => callTrackers('add_to_cart', item, options),

  remove_from_cart: (item: CompetitionBaseDto | CompetitionDetailsDTO) =>
    callTrackers('remove_from_cart', item),

  register: () => callTrackers('register'),

  add_payment_info: ({
    items,
    value,
    couponCode,
    paymentMethod
  }: {
    items: {
      competition: CompetitionBaseDto | CompetitionDetailsDTO
      tickets: ReservedTicketDTO[]
    }[]
    value: number
    couponCode: string
    paymentMethod: string
  }) =>
    callTrackers('add_payment_info', {
      items,
      value,
      couponCode,
      paymentMethod
    }),

  purchase: ({
    groupedTicketsByCompetition,
    value,
    transactionID
  }: {
    groupedTicketsByCompetition: {
      competition: CompetitionBaseDto | CompetitionDetailsDTO
      tickets: UserTicketDTO[]
    }[]
    value: number
    transactionID: string
  }) =>
    callTrackers('purchase', {
      groupedTicketsByCompetition,
      value,
      transactionID
    })
}

export default tracking
