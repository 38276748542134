import { useMemo } from 'react'

import { MAXIMUM_ALLOWED_TICKETS_PER_COMPETITION_PURCHASE } from '@helpers/constants'

import { StyledSlider, StyledSliderContainer } from './LuckyDipSlider.styled'
import { type ILuckyDipSliderProps } from './LuckyDipSlider.types'

const LuckyDipSlider = (props: ILuckyDipSliderProps) => {
  const {
    value,
    setValue,
    discountZonesEnabled = false,
    disabled = false,
    max = MAXIMUM_ALLOWED_TICKETS_PER_COMPETITION_PURCHASE,
    min = 1
  } = props

  const handleChange = event => {
    setValue(+event.target.value)
  }

  const percentage = useMemo(() => {
    return max === min ? 100 : Math.ceil(((value - min) / (max - min)) * 100)
  }, [max, min, value])

  return (
    <StyledSliderContainer percentage={percentage}>
      <StyledSlider
        type="range"
        min={min}
        max={max}
        step={1}
        discountZonesEnabled={discountZonesEnabled}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        disabledThumb={disabled}
      />
    </StyledSliderContainer>
  )
}

export default LuckyDipSlider
