import { Card, type CardProps, css, styled } from '@mui/material'

import { VisaIcon, MastercardIcon, AmexIcon } from '../../icons'

export const StyledCard = styled(Card, {
  shouldForwardProp: propName => propName !== 'isCursorPointer'
})<
  {
    isCursorPointer: boolean
  } & CardProps
>`
  border-radius: 8px;
  background: rgba(45, 45, 45, 0.7);
  padding: 16px;
  cursor: default;

  ${({ isCursorPointer = false }) => {
    if (isCursorPointer) {
      return css`
        cursor: pointer;
      `
    }
  }}
`

export const StyledForm = styled('form')`
  width: 100%;
`

export const StyledVisaIcon = styled(VisaIcon)`
  width: 34px;
`

export const StyledMastercardIcon = styled(MastercardIcon)`
  width: 35px;
`

export const StyledAmexIcon = styled(AmexIcon)`
  width: 35px;
`
