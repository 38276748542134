import { isNil } from 'lodash-es'

import { observable, action } from 'mobx'

import { DateTime } from 'luxon'

import {
  child,
  get,
  getDatabase,
  onChildChanged,
  ref,
  set
} from 'firebase/database'

import * as Sentry from '@sentry/nextjs'

import {
  type ReservedTicketDTO,
  type UserReservedTicketsForCompetitionDTO,
  type CalculateCartPriceResultDTO,
  TicketsService
} from '@elitecompetitions/client-api'

import { getFirebaseApp } from '@utils/Firebase'

import { authStoreFactory } from './Auth'

const registerUsersReservedTicketsListener = async (
  userId: number,
  changeTicketHandler: () => void
) => {
  const UserReservedTicketsRef = child(
    ref(getDatabase(getFirebaseApp())),
    `UsersReservedTickets/${userId}`
  )
  const snapshot = await get(UserReservedTicketsRef)

  if (!snapshot.exists()) {
    await set(UserReservedTicketsRef, { id: userId })
  }

  changeTicketHandler()

  const ticketsChangedUnsubscribe = onChildChanged(
    UserReservedTicketsRef,
    changeTicketHandler
  )

  return () => {
    ticketsChangedUnsubscribe()
  }
}

export class CartStore {
  timerTickId: number | undefined

  cartClearTimerStepInMS = 1000

  lastSyncedAt = 0

  private unsubscribeReservedTicketsListener: (() => void) | undefined =
    undefined

  @observable cartPrice: CalculateCartPriceResultDTO | null = null

  @observable cartTickets: ReservedTicketDTO[] = []

  @observable cartTicketsOverview: UserReservedTicketsForCompetitionDTO[] = []

  @observable timeRemainingInSec: number | null = null

  @observable isRedeemWallet = false

  @observable isSyncingCart = false

  @observable isAdjusting = false

  @observable isClearing = false

  @observable appliedCoupon: string | null = null

  constructor() {
    this.setIsRedeemWallet = this.setIsRedeemWallet.bind(this)
    this.setAppliedCoupon = this.setAppliedCoupon.bind(this)
    this.setIsAdjusting = this.setIsAdjusting.bind(this)
    this.setIsSyncingCart = this.setIsSyncingCart.bind(this)
    this.setAppliedCoupon = this.setAppliedCoupon.bind(this)
    this.setCartPrice = this.setCartPrice.bind(this)
  }

  @action
  setIsSyncingCart(isSyncingCart: boolean) {
    this.isSyncingCart = isSyncingCart
  }

  @action
  setIsAdjusting(isAdjusting: boolean) {
    this.isAdjusting = isAdjusting
  }

  @action
  setIsRedeemWallet(isRedeemWallet: boolean) {
    this.isRedeemWallet = isRedeemWallet
  }

  @action
  setIsClearing(isClearing: boolean) {
    this.isClearing = isClearing
  }

  @action
  setAppliedCoupon(appliedCoupon: string | null) {
    this.appliedCoupon = appliedCoupon
  }

  @action
  setCartPrice(cartPrice: CalculateCartPriceResultDTO | null) {
    this.cartPrice = cartPrice
  }

  async attachUserReservedTicketListeners() {
    this.unsubscribeReservedTicketsListener =
      await registerUsersReservedTicketsListener(
        authStoreFactory().profile.id,
        async () => {
          try {
            await this.syncAll()
          } catch (error) {
            Sentry.captureException(error)
          }
        }
      )
  }

  async removeUserReservedTicketListeners() {
    this.unsubscribeReservedTicketsListener?.()
  }

  async syncAll() {
    this.setIsSyncingCart(true)

    const [cartTickets, cartTicketsOverviewResponse] = await Promise.all([
      TicketsService.getUserReservedTickets(),
      TicketsService.getUserReservedTicketsOverview()
    ])

    const {
      data: cartTicketsOverview = [],
      extra: { cartClearingTime = null }
    } = cartTicketsOverviewResponse

    this.setTicketsData({
      cartTickets,
      cartTicketsOverview
    })

    const cartPrice = await TicketsService.calculateCartPrice({
      requestBody: {
        couponCode: this.appliedCoupon,
        useWallet: this.isRedeemWallet
      }
    })

    this.setCartPrice(cartPrice)

    this.syncCartTimer(cartClearingTime)

    if (this.timerTickId) {
      clearInterval(this.timerTickId)
    }

    this.setIsSyncingCart(false)
    this.setIsAdjusting(false)
    this.setIsClearing(false)

    this.timerTickId = window.setInterval(() => {
      this.timerCountdown()
    }, this.cartClearTimerStepInMS)
  }

  syncCartTimer(cartClearingTime: string | null) {
    if (isNil(cartClearingTime)) {
      return
    }

    const now = DateTime.now()
    const cartClearingDateTime = DateTime.fromISO(cartClearingTime)

    const remainingTimeStampInSec = cartClearingDateTime.diff(
      now,
      'seconds'
    ).seconds

    this.setTimer(remainingTimeStampInSec)
    this.timerCountdown()
  }

  @action
  timerCountdown() {
    if (this.timeRemainingInSec > 0) {
      this.setTimer(
        this.timeRemainingInSec - this.cartClearTimerStepInMS / 1000
      )
    }
  }

  @action
  setTimer(timeInSec: number) {
    this.timeRemainingInSec = timeInSec < 0 ? 0 : timeInSec
  }

  @action
  setTicketsData({
    cartTickets = [],
    cartTicketsOverview = []
  }: {
    cartTickets: ReservedTicketDTO[]
    cartTicketsOverview: UserReservedTicketsForCompetitionDTO[]
  }) {
    this.lastSyncedAt = Date.now()

    this.cartTickets = cartTickets
    this.cartTicketsOverview = cartTicketsOverview
  }

  @action
  async removeFromCart(ticket: ReservedTicketDTO) {
    await TicketsService.clearCart({
      ticketId: ticket.ticketId
    })
  }

  @action
  successClearCart() {
    this.setTicketsData({
      cartTickets: [],
      cartTicketsOverview: []
    })
  }
}

let cartStore: CartStore

export function cartStoreFactory() {
  if (!process.browser) {
    cartStore = new CartStore()
  }
  if (process.browser && !cartStore) {
    cartStore = new CartStore()
  }

  return cartStore
}

export default cartStore
