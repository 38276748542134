import { Stack, Typography } from '@mui/material'

import { numericWithComma } from '@helpers'

import Badge from '../Badge'

import { type ICompetitionCardDiscountBadge } from './CompetitionCardDiscountBadge.types'
import { StyledOriginalPriceTypography } from './CompetitionCardDiscountBadge.styled'

const CompetitionCardDiscountBadge = (props: ICompetitionCardDiscountBadge) => {
  const { originalPrice, discountPrice, layoutMode = 'fixed' } = props

  return (
    <Badge variant="price" layoutMode={layoutMode}>
      <Stack direction="row" spacing={0.5}>
        {layoutMode === 'fixed' && (
          <>
            <StyledOriginalPriceTypography
              fontFamily="Poppins"
              fontWeight={400}
              lineHeight="20px"
              fontSize="16px"
            >
              {`£${numericWithComma(originalPrice)}`}
            </StyledOriginalPriceTypography>

            <Typography
              fontFamily="Poppins"
              fontWeight={700}
              lineHeight="20px"
              fontSize="16px"
            >
              {`£${numericWithComma(discountPrice)}`}
            </Typography>
          </>
        )}

        {layoutMode === 'responsive' && (
          <>
            <StyledOriginalPriceTypography
              fontFamily="Poppins"
              fontWeight={400}
              lineHeight={{
                lg: '20px',
                md: '18px',
                sm: '16px',
                xs: '14px'
              }}
              fontSize={{
                lg: '16px',
                md: '14px',
                sm: '12px',
                xs: '10px'
              }}
            >
              {`£${numericWithComma(originalPrice)}`}
            </StyledOriginalPriceTypography>

            <Typography
              fontFamily="Poppins"
              fontWeight={700}
              lineHeight={{
                lg: '20px',
                md: '18px',
                sm: '16px',
                xs: '14px'
              }}
              fontSize={{
                lg: '16px',
                md: '14px',
                sm: '12px',
                xs: '10px'
              }}
            >
              {`£${numericWithComma(discountPrice)}`}
            </Typography>
          </>
        )}
      </Stack>
    </Badge>
  )
}

export default CompetitionCardDiscountBadge
