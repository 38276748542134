import { isNil } from 'lodash-es'

import { useCallback, useMemo } from 'react'

import Link from 'next/link'

import { Stack } from '@mui/material'

import tracking from '@utils/tracking'

import { getLocalAssetFromCDN } from '@helpers'

import { useJackpot } from '@hooks/competition/useJackpot'

import CompetitionCardJackpotBadge from '../CompetitionCardJackpotBadge'
import CdnImage from '../CdnImage'
import CompetitionCardBadges from '../CompetitionCardBadges'

import {
  StyledEnterButton,
  StyledBadgeContainer,
  StyledCard,
  StyledCardContent,
  StyledCardContentStack,
  StyledCardMedia,
  StyledCardMediaContainer,
  StyledClosedEvent,
  StyledClosedEventBadge,
  StyledClosedEventContainer,
  StyledSoldOutBadge,
  StyledSoldOutTypography,
  StyledCompetitionNameTypography,
  StyledClosedEventTypography
} from './CompetitionCard.styled'
import { type ICompetitionCardProps } from './CompetitionCard.types'

const CompetitionCard = (props: ICompetitionCardProps) => {
  const {
    competition,
    canShowName = true,
    isPast = false,
    analytics = null,
    layoutMode = 'fixed',
    showEndingSoonBadge = true
  } = props

  const { hasJackpot = false } = competition

  const { jackpotAmount = 0, isLoading: isLoadingJackpot = false } = useJackpot(
    {
      competition
    }
  )

  const canShowCompetitionBadges = useMemo(() => {
    const { isSoldOut = false } = competition

    return !isPast && !isSoldOut
  }, [isPast, competition])

  const canShowEnterBtn = useMemo(() => {
    const { isSoldOut = false } = competition

    return !isPast && !isSoldOut
  }, [isPast, competition])

  const href = useMemo(() => {
    const { slugUrl } = competition

    return `/competitions/${isPast ? `past/${slugUrl}` : slugUrl}`
  }, [competition, isPast])

  const handleAnalytics = useCallback(() => {
    if (isNil(analytics)) {
      return
    }

    tracking.customEvent(analytics.eventName, analytics.payload)
  }, [analytics])

  return (
    <Link href={href} prefetch={false}>
      <a>
        <StyledCard onClick={handleAnalytics}>
          <StyledCardMediaContainer>
            <StyledCardMedia isPast={isPast}>
              <CdnImage
                alt={competition.name}
                image={competition.cdnImage}
                placeholder={competition.imagePlaceholder}
                desktop="medium"
                fallbackSrc={getLocalAssetFromCDN(
                  '/assets/img/competition-default-image.webp'
                )}
              />
            </StyledCardMedia>

            <StyledBadgeContainer
              layoutMode={layoutMode}
              isOnTop={false}
              isJackpot={false}
            >
              {canShowCompetitionBadges && (
                <CompetitionCardBadges
                  competition={competition}
                  layoutMode={layoutMode}
                  showEndingSoonBadge={showEndingSoonBadge}
                />
              )}
            </StyledBadgeContainer>

            <StyledBadgeContainer
              layoutMode={layoutMode}
              isOnTop={true}
              isJackpot={true}
            >
              {canShowCompetitionBadges && hasJackpot && (
                <Stack direction="row" justifyContent="flex-start">
                  <CompetitionCardJackpotBadge
                    layoutMode={layoutMode}
                    isLoadingJackpot={isLoadingJackpot}
                    jackpotAmount={jackpotAmount}
                  />
                </Stack>
              )}
            </StyledBadgeContainer>

            {isPast && (
              <StyledClosedEventContainer>
                <StyledClosedEvent>
                  <StyledClosedEventBadge>
                    <StyledClosedEventTypography
                      layoutMode={layoutMode}
                      fontFamily="Poppins"
                      fontWeight={400}
                      lineHeight={{
                        lg: '40px',
                        md: '32px',
                        sm: '32px',
                        xs: '24px'
                      }}
                      fontSize={{
                        lg: '48px',
                        md: '40px',
                        sm: '32px',
                        xs: '24px'
                      }}
                    >
                      Closed
                    </StyledClosedEventTypography>
                  </StyledClosedEventBadge>
                </StyledClosedEvent>
              </StyledClosedEventContainer>
            )}
          </StyledCardMediaContainer>

          <StyledCardContent layoutMode={layoutMode}>
            <StyledCardContentStack spacing={2} justifyContent="space-between">
              {canShowName && (
                <StyledCompetitionNameTypography
                  layoutMode={layoutMode}
                  fontFamily="Poppins"
                  fontWeight={500}
                  lineHeight={{
                    lg: '24px',
                    md: '22px',
                    sm: '20px',
                    xs: '18px'
                  }}
                  fontSize={{
                    lg: '18px',
                    md: '16px',
                    sm: '16px',
                    xs: '14px'
                  }}
                >
                  {competition.name}
                </StyledCompetitionNameTypography>
              )}

              {canShowEnterBtn && (
                <StyledEnterButton layoutMode={layoutMode} size="small">
                  Enter
                </StyledEnterButton>
              )}

              {!isPast && competition.isSoldOut && (
                <StyledSoldOutBadge layoutMode={layoutMode}>
                  <StyledSoldOutTypography
                    layoutMode={layoutMode}
                    fontFamily="Poppins"
                    fontWeight={500}
                    lineHeight={{
                      lg: '18px',
                      md: '18px',
                      sm: '16px',
                      xs: '16px'
                    }}
                    fontSize={{
                      lg: '14px',
                      md: '14px',
                      sm: '12px',
                      xs: '12px'
                    }}
                  >
                    Sold Out
                  </StyledSoldOutTypography>
                </StyledSoldOutBadge>
              )}
            </StyledCardContentStack>
          </StyledCardContent>
        </StyledCard>
      </a>
    </Link>
  )
}

export default CompetitionCard
