import { observable, action } from 'mobx'

export class BasketWidgetStore {
  @observable isOpen: boolean = false

  constructor() {
    this.setIsOpen = this.setIsOpen.bind(this)
  }

  @action
  setIsOpen(isOpen: boolean) {
    this.isOpen = isOpen
  }
}

let basketWidgetStore: BasketWidgetStore

export function basketWidgetStoreFactory() {
  if (!process.browser) {
    basketWidgetStore = new BasketWidgetStore()
  }

  if (process.browser && !basketWidgetStore) {
    basketWidgetStore = new BasketWidgetStore()
  }

  return basketWidgetStore
}

export default basketWidgetStore
