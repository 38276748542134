import { isBrowser } from '@helpers/isBrowser'

export enum AppEnvTypeEnum {
  STAGING = 'staging',
  PRODUCTION = 'production'
}

export enum NodeEnvTypeEnum {
  DEVELOPMENT = 'development',
  PRODUCTION = 'production'
}

interface IConfig {
  hotJar: {
    id: string
    version: string
    isAvailable: boolean
  }
  externalLinks: {
    appStoreUrl: string
    playStoreUrl: string
    oneLinkUrl: string
  }
  node: {
    env: NodeEnvTypeEnum
  }
  sentry: {
    isAvailable: boolean
    dsn: string
    release: string
  }
  firebase: {
    apiKey: string
    authDomain: string
    databaseURL: string
    projectId: string
    storageBucket: string
    messagingSenderId: string
  }
  app: {
    env: AppEnvTypeEnum
    url: string
  }
  cdn: {
    assets: string
    images: string
  }
  googlePay: {
    merchantId: string
    merchantName: string
    isAvailable: boolean
    env: google.payments.api.Environment
  }
  api: {
    v1: string
    v2: string
    externalCoreApiUrl: string
    internalCoreApiUrl: string
  }
  cko: {
    publicKey: string
    riskJsUrl: string
    isAvailable: boolean
    riskJsActive: boolean
  }
  applePay: {
    isAvailable: boolean
    merchantId: string
  }
  matomo: {
    isAvailable: boolean
    siteId: string
    trackingRequestTargetPath: string
    url: string
    containerUrl: string
  }
  adalyser: {
    clientId: string
    isAvailable: boolean
  }
  security: {
    cspRules: string
    cspRulesAvailable: boolean
  }
  snapchat: {
    id: string
    isAvailable: boolean
  }
  facebook: {
    id: string
    isAvailable: boolean
  }
  iubenda: {
    isAvailable: boolean
    id: string
  }
  klaviyo: {
    isAvailable: boolean
    publicKey: string
  }
  googleAnalytics: {
    gtm: {
      id: string
      isAvailable: boolean
    }
    ga4: {
      id: string
      isAvailable: boolean
    }
  }
  googleSearchConsole: {
    id: string
  }
  payPal: {
    isAvailable: boolean
  }
  appsFlyer: {
    workspaceId: string
    isAvailable: boolean
  }
  coreApiUrl?: string
}

const config: IConfig = {
  payPal: {
    isAvailable: process.env.PAYPAL_AVAILABLE === 'true'
  },
  appsFlyer: {
    workspaceId: process.env.APPS_FLYER_WORKSPACE_ID,
    isAvailable: process.env.APPS_FLYER_AVAILABLE === 'true'
  },
  hotJar: {
    version: process.env.HOTJAR_VERSION,
    id: process.env.HOTJAR_ID,
    isAvailable: process.env.HOTJAR_AVAILABLE === 'true'
  },
  externalLinks: {
    appStoreUrl: process.env.IOS_APP_STORE_URL,
    playStoreUrl: process.env.ANDROID_PLAY_STORE_URL,
    oneLinkUrl: process.env.ONE_LINK_URL
  },
  node: {
    env: process.env.NODE_ENV as NodeEnvTypeEnum
  },
  sentry: {
    isAvailable: process.env.SENTRY_AVAILABLE === 'true',
    dsn: process.env.SENTRY_DSN_WEB,
    release: process.env.SENTRY_RELEASE
  },
  firebase: {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.FIREBASE_DATABASE_URL,
    projectId: process.env.FIREBASE_PROJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGE_SENDER_ID
  },
  app: {
    env: process.env.APP_ENV as AppEnvTypeEnum,
    url: process.env.APP_URL
  },
  cdn: {
    assets: process.env.CDN_ASSETS,
    images: process.env.CDN_IMAGES
  },
  googlePay: {
    merchantId: process.env.GOOGLE_PAYMENT_MERCHANT_ID,
    merchantName: process.env.GOOGLE_PAYMENT_MERCHANT_NAME,
    isAvailable: process.env.GOOGLE_PAY_AVAILABLE === 'true',
    env: process.env.GOOGLE_PAYMENT_ENV as google.payments.api.Environment
  },
  cko: {
    publicKey: process.env.CKO_PUBLIC_KEY,
    isAvailable: process.env.CKO_AVAILABLE === 'true',
    riskJsUrl: process.env.RISK_JS_URL,
    riskJsActive: process.env.RISK_JS_ACTIVE === 'true'
  },
  applePay: {
    isAvailable: process.env.APPLE_PAY_AVAILABLE === 'true',
    merchantId: process.env.APPLE_PAY_MERCHANT_ID
  },
  matomo: {
    isAvailable: process.env.MATOMO_AVAILABLE === 'true',
    siteId: process.env.MATOMO_SITE_ID,
    trackingRequestTargetPath: process.env.MATOMO_TRACKING_REQUEST_TARGET_PATH,
    url: process.env.MATOMO_URL,
    containerUrl: process.env.MATOMO_CONTAINER_URL
  },
  iubenda: {
    id: process.env.IUBENDA_ID,
    isAvailable: process.env.IUBENDA_AVAILABLE === 'true'
  },
  adalyser: {
    clientId: process.env.ADALYSER_CLIENT_ID,
    isAvailable: process.env.ADALYSER_AVAILABLE === 'true'
  },
  security: {
    cspRules: process.env.CSP_RULES,
    cspRulesAvailable: process.env.CSP_RULES_AVAILABLE === 'true'
  },
  klaviyo: {
    isAvailable: process.env.KLAVIYO_AVAILABLE === 'true',
    publicKey: process.env.KLAVIYO_PUBLIC_KEY
  },
  facebook: {
    id: process.env.FB_PIXEL_ID,
    isAvailable: process.env.FB_PIXEL_AVAILABLE === 'true'
  },
  snapchat: {
    id: process.env.SNAPCHAT_ID,
    isAvailable: process.env.SNAPCHAT_AVAILABLE === 'true'
  },
  googleAnalytics: {
    gtm: {
      id: process.env.GTM_ID,
      isAvailable: process.env.GTM_AVAILABLE === 'true'
    },
    ga4: {
      id: process.env.GA4_ID,
      isAvailable: process.env.GA4_AVAILABLE === 'true'
    }
  },
  api: {
    v1: process.env.API_URL,
    v2: process.env.API_URL_V2,
    externalCoreApiUrl: process.env.BACKEND_API_EXTERNAL,
    internalCoreApiUrl: process.env.BACKEND_API_INTERNAL
  },
  googleSearchConsole: {
    id: process.env.GOOGLE_SEARCH_CONSOLE_ID
  }
}

Object.defineProperty(config, 'coreApiUrl', {
  get: function () {
    return isBrowser()
      ? this.api.externalCoreApiUrl
      : this.api.internalCoreApiUrl
  }
})

export default config
