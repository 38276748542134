import { Typography } from '@lib/ui'

import { type IJackpotCharityDataBadgeProps } from './JackpotCharityDataBadge.types'
import {
  StyledJackpotCharityDataBadgeContainer,
  StyledOdometer
} from './JackpotCharityDataBadge.styled'

const JackpotCharityDataBadge = (props: IJackpotCharityDataBadgeProps) => {
  const {
    charityJackpotAmount = 0,
    isLoadingJackpot = false,
    showLabel = true,
    ...otherProps
  } = props

  return (
    <StyledJackpotCharityDataBadgeContainer
      justifyContent="center"
      borderRadius={{
        lg: '8px',
        md: '8px',
        sm: '6px',
        xs: '6px'
      }}
      padding={{
        lg: '8px',
        md: '8px',
        sm: '6px',
        xs: '6px'
      }}
      {...otherProps}
    >
      {!isLoadingJackpot && showLabel && (
        <Typography
          fontVariant="heading-4"
          weight="semibold"
          width="55%"
          textAlign="right"
        >
          CHARITY JACKPOT
        </Typography>
      )}

      {isLoadingJackpot && (
        <Typography
          fontVariant="heading-4"
          weight="semibold"
          width="100%"
          textAlign="center"
        >
          Syncing... <i className="fa-regular fa-clover fa-spin" />
        </Typography>
      )}

      {!isLoadingJackpot && (
        <StyledOdometer
          showLabel={showLabel}
          enableScrollSpy={true}
          scrollSpyOnce={true}
          preserveValue={true}
          end={charityJackpotAmount}
          duration={3}
          separator=","
          decimals={2}
          decimal="."
          prefix="£"
        />
      )}
    </StyledJackpotCharityDataBadgeContainer>
  )
}

export default JackpotCharityDataBadge
