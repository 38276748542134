import { css, styled } from '@mui/material'

import { IBadgeProps } from './Badge.types'

export const StyledBadge = styled('div', {
  shouldForwardProp: propName =>
    propName !== 'variant' &&
    propName !== 'layoutMode' &&
    propName !== 'borderRadius'
})<IBadgeProps>`
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;

  ${({ borderRadius = '4px' }) => {
    return css`
      border-radius: ${borderRadius};
    `
  }}

  ${({ layoutMode = 'fixed' }) => {
    if (layoutMode === 'fixed') {
      return css`
        padding: 6px 12px;
      `
    }

    if (layoutMode === 'responsive') {
      return css`
        padding: 4px 8px;

        @media (min-width: 600px) {
          padding: 6px 12px;
        }
      `
    }
  }}

  ${({ variant = 'price' }) => {
    if (variant === 'price') {
      return css`
        background: var(--Secondary);
        color: var(--Neutral1);
      `
    }

    if (variant === 'endingSoon') {
      return css`
        background: var(--ThirdVariant);
        color: var(--SecondaryVariant);
      `
    }

    if (variant === 'freeToEnter') {
      return css`
        background: var(--Neutral1);
        color: var(--Neutral9);
      `
    }

    if (variant === 'appExclusive') {
      return css`
        background: var(--PrimaryVariant);
        color: var(--Neutral1);
      `
    }

    if (variant === 'upcoming') {
      return css`
        background: var(--Neutral1);
        color: var(--Primary);
      `
    }

    if (variant === 'jackpot') {
      return css`
        color: var(--Neutral1);
        background: radial-gradient(
            161.58% 147.31% at 19.54% -14.58%,
            rgba(0, 0, 0, 0.23) 0%,
            rgba(0, 0, 0, 0) 86.18%
          ),
          radial-gradient(
            130.11% 135.29% at 104.98% 100.23%,
            rgba(255, 255, 255, 0.24) 0%,
            rgba(255, 255, 255, 0) 69.79%,
            rgba(255, 255, 255, 0) 100%
          ),
          linear-gradient(
            271deg,
            var(--SecondaryVariant) -31.44%,
            var(--Primary) 91.35%
          );
      `
    }
  }}
`
